if(module.hot) {
    require('../styles/app.scss');
}

/**
 * Move cart modals to end of <body>.
 */
function cartModals() {
    const $cartModals = jQuery('#modalEmptyCart, #modalRemoveItem');

    $cartModals.each(function () {
        const $form = jQuery(this).closest('form');

        if($form) {
            $form.appendTo('body');
        }
    });
}

/**
 * Move payment method modals to end of <body>.
 */
function paymentMethodModals() {
    const $modals = jQuery('#modalBillingAddress');

    $modals.each(function () {
        jQuery(this).appendTo('body');
    });
}


jQuery(document).ready(function () {
    cartModals();
    paymentMethodModals();
});
